import React from "react";

export default () => (
  <div className="container">
    <h1>¿Qué es Balance Iónico?</h1>
    <div>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusamus
        laborum omnis dolorum natus magnam iusto vero incidunt optio id,
        recusandae harum eos minima autem at porro sit sint expedita ullam!
      </p>
      <img width="50%" height="50%" src="imgBalance.jpg" alt="imgBalance" />
    </div>
    <div>
      <h1>¿Por qué es importante?</h1>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae veniam,
        nihil harum odio, dolorum nisi cumque ex quidem explicabo placeat ipsam
        quisquam? Natus fugiat incidunt esse sequi consequuntur aliquam a.
      </p>
    </div>
  </div>
);
